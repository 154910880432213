<template>
  <div>
    <div class="d-flex flex-wrap flex-stack mb-6">
      <h3 class="fw-bolder my-2">Optimization Results</h3>
    </div>
    <div class="row g-5 g-xl-9">
      <div v-for="result in results" :key="result.driver" class="col-md-3">
        <div class="card card-flush h-md-100">
          <div class="card-header">
            <div class="card-title w-100 d-flex justify-content-between">
              <h2>{{ result.driver.name }}</h2>
              <button
                class="btn btn-sm btn-primary"
                @click="assignDriver(result)"
              >
                Assign
              </button>
            </div>
          </div>

          <div class="card-body px-10 py-1">
            <ul v-for="location in result.trips" :key="location.id">
              <li>
                {{ location.address }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card bg-warning card-flush h-md-100">
          <div class="card-header">
            <div class="card-title w-100 d-flex justify-content-between">
              Unable to assign
            </div>
          </div>

          <div class="card-body px-10 py-1">
            <ul v-for="result in noResults" :key="result.id">
              <li>
                {{ result.address }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOptimizationSolution,
  assignDriverToTrips,
} from "@/api/optimize.api";
import { onMounted, ref } from "@vue/composition-api";
import { useNotify } from "@/utils/notify";

export default {
  setup(props, context) {
    const { notify } = useNotify(context);
    const route = context.root.$route;

    const results = ref();
    const noResults = ref();

    onMounted(async () => {
      const { data } = await getOptimizationSolution(
        route.params.id.toString()
      );
      results.value = data.results;
      noResults.value = data.noResults;
    });

    const assignDriver = async (result) => {
      const payload = [];

      let trips = {
        driver_id: result.driver.id,
        trip_id: [],
      };

      result.trips.forEach((trip) => {
        trips.trip_id.push(trip.id);
      });

      payload.push(trips);

      await assignDriverToTrips(payload);
      notify("Driver assigned", `The driver was assigned`);
    };

    return {
      results,
      noResults,

      assignDriver,
    };
  },
};

// const route = useRoute();
// const optimizeApi = useOptimizeApi();
// const dispatchApi = useDispatchApi();

// const results = ref();
// const noResults = ref();

// onMounted(async () => {
//   const { data } = await optimizeApi.getOptimizationSolution(
//     route.params.id.toString()
//   );
//   console.log(data);
//   results.value = data.results;
//   noResults.value = data.noResults;
// });

// const assignDriverToTrips = async (result) => {
//   const payload = [];

//   let trips = {
//     driverId: result.driver.id,
//     tripId: [],
//   };

//   result.trips.forEach((trip) => {
//     trips.tripId.push(trip.id);
//   });

//   payload.push(trips);

//   await dispatchApi.assignDriverToTrips(payload);
//   useSwal().success("Trips have been assigned successfully", "Ok");
// };
</script>
